import { Injectable } from '@angular/core';

import { AlertController } from '@ionic/angular';
import { ExitConfirmation } from '../interfaces/exit-confirmation.interface';
import { DISCARD_MESSAGE, DISCARD_MESSAGE_HEADER, DISCARD_NO_BUTTON_TITLE, DISCARD_YES_BUTTON_TITLE } from './constants';

@Injectable()
export class CanDeactivateUnsaved {
    constructor(
        private readonly alertController: AlertController,
    ) { }

    public async canDeactivate(
        component: ExitConfirmation,
    ): Promise<boolean> {
        return Promise.resolve(true);

        /// TODO: postponed AMP-2077
        // if (!component.confirmExit) {
        //     return Promise.resolve(true);
        // }

        // const alert = await this.alertController.create({
        //     header: DISCARD_MESSAGE_HEADER,
        //     message: DISCARD_MESSAGE,
        //     buttons: [
        //         {
        //             text: DISCARD_YES_BUTTON_TITLE,
        //             role: 'ok',
        //         },
        //         {
        //             text: DISCARD_NO_BUTTON_TITLE,
        //             role: 'cancel',
        //             cssClass: 'secondary',
        //         },
        //     ],
        // });

        // await alert.present();

        // const data = await alert.onDidDismiss();

        // return Promise.resolve(data.role === 'ok');
    }
}
