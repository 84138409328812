import { Inject, Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { BehaviorSubject, Observable, combineLatest, debounceTime, delay, distinctUntilChanged, map, shareReplay, switchMap, tap, zip } from 'rxjs';

import { JobsPlacementsState } from '../../../+state/app-state/app.state';
import { PlacementInterface } from '../../../interfaces/placement.interface';
import { TimesheetApproveV3 } from '../interfaces/timesheet-approve-v3.interface';
import { TimesheetHourBreakV3 } from '../interfaces/timesheet-hour-break-v3.interface';
import { TimesheetHourV3, TimesheetHourV3Complex } from '../interfaces/timesheet-hour-v3.interface';
import { TimesheetV3, TimesheetV3Complex } from '../interfaces/timesheet-v3.interface';

import { TimesheetApprovesService } from './timesheet-approves.service';
import { TimesheetExpensesService } from './timesheet-expenses.service';
import { TimesheetHourBreaksService } from './timesheet-hour-breaks.service';
import { TimesheetHoursService } from './timesheet-hours.service';
import { TimesheetsStoreService } from './timesheets-store.service';
import { TimesheetsService } from './timesheets.service';
import { flatten, isEmpty, isEqual } from 'lodash-es';
import { SectionsEnum } from '../../../enums/sections.enum';
import { STORE_WRAPPER_TOKEN, StoreWrapperInterface } from '@actassa/api';
import { PageOptions } from '../interfaces/page-options.interface';
import { PlacementsService } from '../../../services/placements.service';
import { getTimesheetOptions } from '../../../helpers/get-timesheet-menu-item-options.helper';
import { endOfDay, isAfter, isSameDay, startOfDay, subDays } from 'date-fns';
import { DEFAULT_IS_PIN_ALLOWED, DEFAULT_IS_POSITION_ALLOWED, EMPTY_CONSULTANT_GROUP_NAME } from '../constants';

@Injectable({
    providedIn: 'root',
})
export class TimesheetsFacadeService {
    @Select(JobsPlacementsState.placements$) private _placements$: Observable<Array<PlacementInterface>>;

    public loader$: Observable<boolean>;
    public providerId$ = this.storeWrapper.providerId$;
    public user$ = this.storeWrapper.user$;
    public prohibitedWardNames: Array<string> = [];

    protected readonly _loader$ = new BehaviorSubject<boolean>(false);

    private timesheetHoursLoadActivator$ = new BehaviorSubject<{ placementIds: Array<number>; week: Date }>(null);

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
        private readonly timesheetsService: TimesheetsService,
        private readonly timesheetApprovesService: TimesheetApprovesService,
        private readonly timesheetHoursService: TimesheetHoursService,
        private readonly timesheetHourBreaksService: TimesheetHourBreaksService,
        private readonly timesheetExpensesService: TimesheetExpensesService,
        private readonly timesheetsStoreService: TimesheetsStoreService,
        private readonly placementsService: PlacementsService,
    ) {
        this.loader$ = this.initLoaders$();

        this.timesheetHoursLoadActivator$
            .pipe(
                switchMap((data) => this.loadTimesheets$(data)),
                map((timesheets: Array<TimesheetV3>) => timesheets.map(({ id }) => id)),
                // switchMap((timesheetIds: Array<string>) => this.timesheetExpensesService.getData$(timesheetIds)),
                // map((timesheetExpenses: Array<TimesheetExpenseV3>) => timesheetExpenses.map(({ timesheetId }) => timesheetId)),
                // switchMap((timesheetIds: Array<string>) => this.loadTimesheetApproves$(timesheetIds)),
                // zip([this.loadTimesheetHours$(timesheetIds), this.loadTimesheetApproves$(timesheetIds)])),
                // map(([timesheetHours]: [Array<TimesheetHourV3>, Array<TimesheetApproveV3>]) => timesheetHours.map(({ id }) => id)),
                // switchMap((timesheetHourIds: Array<string>) => this.timesheetHourBreaksService.getData$(timesheetHourIds)),
            )
            .subscribe();
    }

    public startLoading(): void {
        this._loader$.next(true);
    }

    public stopLoading(): void {
        this._loader$.next(false);
    }

    public update(data: { placementIds: Array<number>; week: Date } | null): void {
        this.timesheetHoursLoadActivator$.next(data);
    }

    public getTimesheets$(): Observable<Array<TimesheetV3>> {
        return this.timesheetsStoreService.getTimesheets$();
    }

    public getComplexTimesheets$(): Observable<Array<TimesheetV3Complex>> {
        return combineLatest([
            this.timesheetsStoreService.getTimesheets$(),
            this.timesheetsStoreService.getTimesheetHours$(),
            this.timesheetsStoreService.getTimesheetHourBreaks$(),
            this.timesheetsStoreService.getTimesheetApproves$(),
        ])
            .pipe(
                debounceTime(100),
                delay(0),
                map(([timesheets, timesheetHours, timesheetHourBreaks, timesheetApproves]) => timesheets
                    .map(timesheet => ({
                        ...timesheet,
                        timesheetApproves: timesheetApproves.filter(timesheetApprove => timesheetApprove.timesheetId === timesheet.id),
                        timesheetHours: timesheetHours
                            .filter(timesheetHour => timesheetHour.timesheetId === timesheet.id)
                            .map(timesheetHour => ({
                                ...timesheetHour,
                                timesheetHourBreaks: timesheetHourBreaks
                                    .filter(timesheetHourBreak => timesheetHourBreak.timesheetHourId === timesheetHour.id),
                            })),
                    }))),
            );
    }

    public getComplexTimesheet$(id: string): Observable<TimesheetV3Complex> {
        return this.getComplexTimesheets$()
            .pipe(
                map(timesheets => timesheets.find(timesheet => timesheet.id === id)),
            );
    }

    public getTimesheet$(id: string): Observable<TimesheetV3> {
        return this.timesheetsStoreService.getTimesheets$()
            .pipe(
                map(timesheets => timesheets.find(timesheet => timesheet.id === id)),
            );
    }

    public getTimesheetHours$(timesheetId: string): Observable<Array<TimesheetHourV3>> {
        return this.timesheetsStoreService.getTimesheetHours$()
            .pipe(
                map(timesheetHours => timesheetHours.filter(timesheetHour => timesheetHour.timesheetId === timesheetId)),
            );
    }

    public getComplexTimesheetHours$(timesheetId: string): Observable<Array<TimesheetHourV3Complex>> {
        return combineLatest([
            this.timesheetsStoreService.getTimesheetHours$(),
            this.timesheetsStoreService.getTimesheetHourBreaks$(),
        ])
            .pipe(
                map(([timesheetHours, timesheetHourBreaks]) =>
                    timesheetHours
                        .filter(timesheetHour => timesheetHour.timesheetId === timesheetId)
                        .map(timesheetHour => ({
                            ...timesheetHour,
                            timesheetHourBreaks: timesheetHourBreaks
                                .filter(timesheetHourBreak => timesheetHourBreak.timesheetHourId === timesheetHour.id),
                        })),
                )
            );
    }

    public getTimesheetApproves$(timesheetId: string): Observable<Array<TimesheetApproveV3>> {
        return this.timesheetsStoreService.getTimesheetApproves$()
            .pipe(
                map(timesheetApproves => timesheetApproves.filter(timesheetApprove => timesheetApprove.timesheetId === timesheetId)),
            );
    }

    public getTimesheetHour$(id: string): Observable<TimesheetHourV3> {
        return this.timesheetsStoreService.getTimesheetHours$()
            .pipe(
                map(timesheetHours => timesheetHours.find(timesheetHour => timesheetHour.id === id)),
            );
    }

    public getTimesheetHourBreaks$(timesheetHourId: string): Observable<Array<TimesheetHourBreakV3>> {
        return this.timesheetsStoreService.getTimesheetHourBreaks$()
            .pipe(
                map(timesheetHourBreaks => timesheetHourBreaks
                    .filter(timesheetHourBreak => timesheetHourBreak.timesheetHourId === timesheetHourId)),
            );
    }

    public saveTimesheets(timesheets: Array<TimesheetV3>): void {
        return this.timesheetsStoreService.saveTimesheets(timesheets);
    }

    public saveTimesheetHours(timesheetHours: Array<TimesheetHourV3>): void {
        return this.timesheetsStoreService.saveTimesheetHours(timesheetHours);
    }

    public saveTimesheetHourBreaks(timesheetHourBreaks: Array<TimesheetHourBreakV3>): void {
        return this.timesheetsStoreService.saveTimesheetHourBreaks(timesheetHourBreaks);
    }

    public saveTimesheetApproves(timesheetApproves: Array<TimesheetApproveV3>): void {
        return this.timesheetsStoreService.saveTimesheetApproves(timesheetApproves);
    }

    public getPlacement$(id: number): Observable<PlacementInterface> {
        return this._placements$
            .pipe(
                map(placements => placements.find(placement => placement.placementId === id)),
            );
    }

    public getPlacements$(): Observable<Array<PlacementInterface>> {
        return this._placements$;
    }

    public sendTimesheet$(dto: TimesheetV3): Observable<unknown> {
        return this.timesheetsService.save$(dto);
    }

    public sendTimesheetHours$(dto: any): Observable<any> {
        return this.timesheetHoursService.save$(dto);
    }

    public sendTimesheetApprove$(dto: TimesheetApproveV3): Observable<unknown> {
        return this.timesheetApprovesService.save$(dto);
    }

    public initPageConfig$(): Observable<PageOptions> {
        return combineLatest([
            this.storeWrapper.getMenuItem$(SectionsEnum.TIMESHEETS),
            this._placements$,
        ])
            .pipe(
                distinctUntilChanged(isEqual),
                map(([pageConfig, placements]) => {
                    const options = getTimesheetOptions(pageConfig);

                    if (isEmpty(options.maxWeekBackwardByBranch)) {
                        return options;
                    }

                    let maxWeekBackward = options.maxWeekBackward;

                    const endOfToday = endOfDay(new Date());
                    const startCheckPeriod = startOfDay(subDays(endOfToday, options.lookBackDays));
                    const consultantGroupStartDateMap = new Map<string, Date>();

                    for (const placement of placements) {
                        const placementConsultantGroupName: string = placement.placementConsultantGroup?.groupName || EMPTY_CONSULTANT_GROUP_NAME;
                        const { placementStartDate } = placement;

                        if (isAfter(placementStartDate, startCheckPeriod) || isSameDay(placementStartDate, startCheckPeriod)) {
                            consultantGroupStartDateMap.set(placementConsultantGroupName, placementStartDate);
                        }
                    }

                    for (const branchConfig of options.maxWeekBackwardByBranch) {
                        const hasPlacementInLookBackDays = consultantGroupStartDateMap.get(branchConfig.group);

                        if (hasPlacementInLookBackDays) {
                            maxWeekBackward = branchConfig.maxBack;

                            break;
                        }
                    }

                    return {
                        ...options,
                        maxWeekBackward,
                    };
                }),
                shareReplay(1),
            );
    }

    public getIsPositionAllowed$(placementId: number): Observable<boolean> {
        return combineLatest([
            this.initPageConfig$(),
            this.getPlacement$(placementId),
        ])
            .pipe(
                map(([{ prohibitedWardNames, includePositionConsultantGroupNames, excludePositionConsultantGroupNames }, placement]) => {
                    this.prohibitedWardNames = prohibitedWardNames;

                    const isPositionAllowed = isEmpty(includePositionConsultantGroupNames)
                        ? isEmpty(excludePositionConsultantGroupNames)
                            ? DEFAULT_IS_POSITION_ALLOWED
                            : !excludePositionConsultantGroupNames.includes(placement.placementConsultantGroup?.groupName || '')
                        : includePositionConsultantGroupNames.includes(placement.placementConsultantGroup?.groupName || '');

                    return isPositionAllowed;
                }),
            )
    }

    public getIsPinAllowed$(placementId: number): Observable<boolean> {
        return combineLatest([
            this.initPageConfig$(),
            this.getPlacement$(placementId),
        ])
            .pipe(
                map(([{ includePinEntryConsultantGroupNames, excludePinEntryConsultantGroupNames }, placement]) => {
                    const isPinAllowed = isEmpty(includePinEntryConsultantGroupNames)
                        ? isEmpty(excludePinEntryConsultantGroupNames)
                            ? DEFAULT_IS_PIN_ALLOWED
                            : !excludePinEntryConsultantGroupNames.includes(placement.placementConsultantGroup?.groupName || '')
                        : includePinEntryConsultantGroupNames.includes(placement.placementConsultantGroup?.groupName || '');

                    return isPinAllowed;
                }),
            )
    }

    private loadTimesheetApproves$(timesheetIds: Array<string>): Observable<Array<TimesheetApproveV3>> {
        return this.timesheetApprovesService.load$(timesheetIds)
            .pipe(
                tap(data => this.timesheetsStoreService.saveTimesheetApproves(data)),
            );
    }

    private loadTimesheetHours$(timesheetIds: Array<string>): Observable<Array<TimesheetHourV3>> {
        return this.timesheetHoursService.load$(timesheetIds)
            .pipe(
                tap(data => this.timesheetsStoreService.saveTimesheetHours(data)),
            );
    }

    private loadTimesheets$(data: { placementIds: Array<number>; week: Date; }): Observable<Array<TimesheetV3Complex>> {
        return this.timesheetsService.load$(data)
            .pipe(
                tap(timesheets => {
                    this.timesheetsStoreService.saveTimesheets(timesheets);

                    const timesheetHours = flatten(timesheets.map(timesheet => timesheet.timesheetHours));

                    this.timesheetsStoreService.saveTimesheetHours(timesheetHours);

                    const timesheetHourBreaks = flatten(timesheetHours.map(timesheetHour => timesheetHour.timesheetHourBreaks));

                    this.timesheetsStoreService.saveTimesheetHourBreaks(timesheetHourBreaks);

                    const timesheetApproves = flatten(timesheets.map(timesheet => timesheet.timesheetApproves));

                    this.timesheetsStoreService.saveTimesheetApproves(timesheetApproves);

                    // const timesheetExpenses = flatten(timesheets.map(timesheet => timesheet.timesheetExpenses));
                }),
            );
    }

    private initLoaders$(): Observable<boolean> {
        return combineLatest([
            this._loader$,
            this.timesheetsService.loader$,
            this.timesheetHoursService.loader$,
            this.timesheetHourBreaksService.loader$,
            this.timesheetExpensesService.loader$,
            this.timesheetApprovesService.loader$,
            this.placementsService.loader$,
        ]).pipe(
            map(loaders => loaders.some(Boolean)),
            distinctUntilChanged(),
        );
    }
}
